import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { LanguageSelectorComboBox } from '@eluve/blocks';
import { HStack, VStack, titleTextStyles } from '@eluve/components';
import {
  appointmentLanguagesFragment,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';
import { useTenantUserSettings } from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import { DEFAULT_LANGUAGE } from '@eluve/language-utils';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const setAppointmentLanguageMutation = graphql(
  `
    mutation setAppointmentLanguage(
      $tenantId: uuid!
      $appointmentId: uuid!
      $inputLanguage: String!
      $outputLanguage: String!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $appointmentId }
        _set: { inputLanguage: $inputLanguage, outputLanguage: $outputLanguage }
      ) {
        __typename
        id
        inputLanguage
        outputLanguage
      }
    }
  `,
  [],
);

type AppointmentLanguageSelectorProps = {
  inputLanguage?: boolean;
  outputLanguage?: boolean;
};

export const AppointmentLanguageSelector: React.FC<
  AppointmentLanguageSelectorProps
> = ({ inputLanguage = false, outputLanguage = false }) => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams() ?? '';
  const { settings: userDefaultSettings } = useTenantUserSettings();

  const appointment = useCompleteFragment({
    fragment: appointmentLanguagesFragment,
    key: {
      id: appointmentId,
    },
  });

  const inputLanguageCode =
    appointment?.inputLanguage ??
    userDefaultSettings?.inputLanguage ??
    DEFAULT_LANGUAGE;
  const outputLanguageCode =
    appointment?.outputLanguage ??
    userDefaultSettings?.outputLanguage ??
    DEFAULT_LANGUAGE;

  const [setAppointmentLanguage] = useMutation(setAppointmentLanguageMutation, {
    optimisticResponse: (input) => ({
      updateAppointmentsByPk: {
        __typename: 'Appointments' as const,
        id: appointmentId,
        inputLanguage: input?.inputLanguage ?? inputLanguageCode,
        outputLanguage: input?.outputLanguage ?? outputLanguageCode,
      },
    }),
    onError: () => toast.error('Failed to update language'),
  });

  // On load, select the defaults
  useEffect(() => {
    if (!appointment?.inputLanguage) {
      setAppointmentLanguage({
        variables: {
          tenantId,
          appointmentId,
          inputLanguage: inputLanguageCode,
          outputLanguage: outputLanguageCode,
        },
      });
    }
  }, [
    appointment,
    appointmentId,
    tenantId,
    inputLanguageCode,
    outputLanguageCode,
    setAppointmentLanguage,
  ]);

  const preferredLanguages = [];
  if (userDefaultSettings?.inputLanguage) {
    preferredLanguages.push(userDefaultSettings.inputLanguage);
  }
  if (userDefaultSettings?.outputLanguage) {
    preferredLanguages.push(userDefaultSettings.outputLanguage);
  }

  return (
    <VStack gap={1} className="items-start md:flex-row">
      {inputLanguage && (
        <HStack>
          <p
            className={titleTextStyles({
              className: 'text-gray-9 text-nowrap',
              size: 2,
            })}
          >
            Spoken Language:
          </p>
          <LanguageSelectorComboBox
            selectButtonStyles={titleTextStyles({
              className: 'text-gray-9 border-none mr-5 text-nowrap',
              size: 2,
            })}
            disabled={appointment?.status !== 'NOT_STARTED'}
            selectedLanguageCode={inputLanguageCode}
            onSelectedLanguageCode={async (newCode) => {
              await setAppointmentLanguage({
                variables: {
                  tenantId,
                  appointmentId,
                  inputLanguage: newCode,
                  outputLanguage: outputLanguageCode,
                },
              });
            }}
            preferredLanguages={preferredLanguages}
            showLanguageIcon={false}
          />
        </HStack>
      )}

      {outputLanguage && (
        <HStack>
          <p
            className={titleTextStyles({
              className: 'text-gray-9 text-nowrap',
              size: 2,
            })}
          >
            Notes Language:
          </p>
          <LanguageSelectorComboBox
            selectButtonStyles={titleTextStyles({
              className: 'text-gray-9 border-none text-nowrap',
              size: 2,
            })}
            selectedLanguageCode={outputLanguageCode}
            onSelectedLanguageCode={async (newCode) => {
              await setAppointmentLanguage({
                variables: {
                  tenantId,
                  appointmentId,
                  inputLanguage: inputLanguageCode,
                  outputLanguage: newCode,
                },
              });
            }}
            preferredLanguages={preferredLanguages}
            showLanguageIcon={false}
          />
        </HStack>
      )}
    </VStack>
  );
};
