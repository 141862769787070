import locale from 'locale-codes';
import intersection from 'lodash/intersection';
import invert from 'lodash/invert';
import pickBy from 'lodash/pickBy';

export const DeepgramSupportedLanguages = {
  Bulgarian: 'bg-BG',
  Catalan: 'ca-ES',
  'Chinese (Simplified)': 'zh-CN',
  'Chinese (Simplified Script)': 'zh-Hans',
  'Chinese (Traditional, Taiwan)': 'zh-TW',
  'Chinese (Traditional Script)': 'zh-Hant',
  'Chinese (Cantonese, Hong Kong)': 'zh-HK',
  Czech: 'cs-CZ',
  Danish: 'da-DK',
  Dutch: 'nl-NL',
  English: 'en-US',
  'English (US)': 'en-US',
  'English (Australia)': 'en-AU',
  'English (UK)': 'en-GB',
  'English (New Zealand)': 'en-NZ',
  'English (India)': 'en-IN',
  Estonian: 'et-EE',
  Finnish: 'fi-FI',
  Flemish: 'nl-BE',
  French: 'fr-FR',
  'French (Canada)': 'fr-CA',
  German: 'de-DE',
  'German (Switzerland)': 'de-CH',
  Greek: 'el-GR',
  Hindi: 'hi-IN',
  Hungarian: 'hu-HU',
  Indonesian: 'id-ID',
  Italian: 'it-IT',
  Japanese: 'ja-JP',
  Korean: 'ko-KR',
  Latvian: 'lv-LV',
  Lithuanian: 'lt-LT',
  Malay: 'ms-MY',
  'Multilingual (Spanish + English)': 'multi',
  Norwegian: 'nb-NO',
  Polish: 'pl-PL',
  Portuguese: 'pt-PT',
  'Portuguese (Brazil)': 'pt-BR',
  Romanian: 'ro-RO',
  Russian: 'ru-RU',
  Slovak: 'sk-SK',
  Spanish: 'es-ES',
  'Spanish (Latin America)': 'es-419',
  Swedish: 'sv-SE',
  Thai: 'th-TH',
  Turkish: 'tr-TR',
  Ukrainian: 'uk-UA',
  Vietnamese: 'vi-VN',
} as const;

export const DeepgramSupportedLanguagesByCode = invert(
  DeepgramSupportedLanguages,
);

// Azure supports more languages but these are just the ones that can be auto identified based solely on voice
// https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=language-identification
export const AzureSupportedIdentificableLanguages = {
  'Arabic (UAE)': 'ar-AE',
  'Arabic (Bahrain)': 'ar-BH',
  'Arabic (Algeria)': 'ar-DZ',
  'Arabic (Egypt)': 'ar-EG',
  'Arabic (Iraq)': 'ar-IQ',
  'Arabic (Jordan)': 'ar-JO',
  'Arabic (Kuwait)': 'ar-KW',
  'Arabic (Libya)': 'ar-LY',
  'Arabic (Morocco)': 'ar-MA',
  'Arabic (Oman)': 'ar-OM',
  'Arabic (Qatar)': 'ar-QA',
  'Arabic (Saudi Arabia)': 'ar-SA',
  'Arabic (Syria)': 'ar-SY',
  'Arabic (Yemen)': 'ar-YE',
  Bengali: 'bn-IN',
  Bulgarian: 'bg-BG',
  Catalan: 'ca-ES',
  'Chinese (Simplified)': 'zh-CN',
  'Chinese (Cantonese, Hong Kong)': 'zh-HK',
  'Chinese (Traditional, Taiwan)': 'zh-TW',
  Croatian: 'hr-HR',
  Czech: 'cs-CZ',
  Danish: 'da-DK',
  Dutch: 'nl-NL',
  'English (Australia)': 'en-AU',
  'English (Canada)': 'en-CA',
  'English (Ghana)': 'en-GH',
  'English (Hong Kong)': 'en-HK',
  'English (India)': 'en-IN',
  'English (Ireland)': 'en-IE',
  'English (Kenya)': 'en-KE',
  'English (New Zealand)': 'en-NZ',
  'English (Nigeria)': 'en-NG',
  'English (Philippines)': 'en-PH',
  'English (Singapore)': 'en-SG',
  'English (South Africa)': 'en-ZA',
  'English (Tanzania)': 'en-TZ',
  'English (UK)': 'en-GB',
  'English (US)': 'en-US',
  Estonian: 'et-EE',
  Finnish: 'fi-FI',
  'French (Canada)': 'fr-CA',
  French: 'fr-FR',
  German: 'de-DE',
  Greek: 'el-GR',
  Gujarati: 'gu-IN',
  Hebrew: 'he-IL',
  Hindi: 'hi-IN',
  Hungarian: 'hu-HU',
  Indonesian: 'id-ID',
  Irish: 'ga-IE',
  Italian: 'it-IT',
  Japanese: 'ja-JP',
  Kannada: 'kn-IN',
  Malayalam: 'ml-IN',
  Korean: 'ko-KR',
  Latvian: 'lv-LV',
  Lithuanian: 'lt-LT',
  Maltese: 'mt-MT',
  Marathi: 'mr-IN',
  Norwegian: 'nb-NO',
  Polish: 'pl-PL',
  'Portuguese (Brazil)': 'pt-BR',
  'Portuguese (Portugal)': 'pt-PT',
  Romanian: 'ro-RO',
  Russian: 'ru-RU',
  Slovak: 'sk-SK',
  Slovenian: 'sl-SI',
  'Spanish (Argentina)': 'es-AR',
  'Spanish (Bolivia)': 'es-BO',
  'Spanish (Chile)': 'es-CL',
  'Spanish (Colombia)': 'es-CO',
  'Spanish (Costa Rica)': 'es-CR',
  'Spanish (Cuba)': 'es-CU',
  'Spanish (Dominican Republic)': 'es-DO',
  'Spanish (Ecuador)': 'es-EC',
  'Spanish (El Salvador)': 'es-SV',
  'Spanish (Equatorial Guinea)': 'es-GQ',
  'Spanish (Guatemala)': 'es-GT',
  'Spanish (Honduras)': 'es-HN',
  'Spanish (Mexico)': 'es-MX',
  'Spanish (Nicaragua)': 'es-NI',
  'Spanish (Panama)': 'es-PA',
  'Spanish (Paraguay)': 'es-PY',
  'Spanish (Peru)': 'es-PE',
  'Spanish (Puerto Rico)': 'es-PR',
  'Spanish (Spain)': 'es-ES',
  'Spanish (Uruguay)': 'es-UY',
  'Spanish (US)': 'es-US',
  'Spanish (Venezuela)': 'es-VE',
  Swedish: 'sv-SE',
  Tamil: 'ta-IN',
  Telugu: 'te-IN',
  Thai: 'th-TH',
  Turkish: 'tr-TR',
  Ukrainian: 'uk-UA',
  Vietnamese: 'vi-VN',
} as const;

export const AzureSupportedIdentificableLanguagesByCode = invert(
  AzureSupportedIdentificableLanguages,
);

export const getLanguageNameFromCode = (code?: string | null) => {
  if (!code) {
    return null;
  }
  return locale.getByTag(code)?.name ?? null;
};

// Combine the languages supported by Deepgram and Azure
// If we'll need provider specific language, we will need to decouple this.
export const SupportedLanguagesByCode = pickBy(
  DeepgramSupportedLanguagesByCode,
  (_key, value) =>
    intersection(
      Object.values(DeepgramSupportedLanguages),
      Object.values(AzureSupportedIdentificableLanguages),
    ).includes(value as any),
) as Record<string, string>;

export const SupportedLanguages = invert(SupportedLanguagesByCode) as Record<
  string,
  string
>;

export const DEFAULT_LANGUAGE = 'en-US';
