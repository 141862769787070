import React from 'react';

import { VStack } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import {
  AppointmentLanguageSelector,
  PendingTranscriptionLoader,
} from '@eluve/frontend-feature-appointment';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { useTranscriptionMachineSelector } from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentCurrentTranscript } from './AppointmentCurrentTranscript';
import { AppointmentInSessionTranscript } from './AppointmentInSessionTranscript';
import { SessionStartedAtTimestamp } from './SessionStartedAtTimestamp';
import { UploadingFileLoader } from './UploadingFileLoader';

export const AppointmentTranscription: React.FC = () => {
  const { appointmentId, tenantId } = useAppointmentContext();
  const isCurrentAppointmentInSession = useTranscriptionMachineSelector(
    (s) =>
      s.matches({ InSession: 'Recording' }) &&
      s.context.sessionAppointmentId === appointmentId,
  );

  return (
    <>
      <VStack className="border-gray-5 rounded-lg border p-5">
        <VStack
          wFull
          justify="between"
          className="items-start md:flex-row md:items-center"
        >
          <SessionStartedAtTimestamp />

          <div>
            <FeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector inputLanguage />
            </FeatureFlaggedComponent>
          </div>
        </VStack>

        {isCurrentAppointmentInSession && <AppointmentInSessionTranscript />}
        {!isCurrentAppointmentInSession && <AppointmentCurrentTranscript />}
      </VStack>

      {!isCurrentAppointmentInSession && (
        <>
          <PendingTranscriptionLoader
            tenantId={tenantId}
            appointmentId={appointmentId}
          />
          <UploadingFileLoader appointmentId={appointmentId} />
        </>
      )}
    </>
  );
};
